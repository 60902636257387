<template>
    <div class="payPassword">
        <s-header :name="$t('LAN.pay_setting')"></s-header>

        <van-empty v-if="success"
            class="custom-image"
            :image='require(`@/assets/common/success.png`)'
            :description="$t('LAN.success')"/>

        <!-- 设置支付密码 -->
        <div class="content" v-else>
            <div class="tips" >
                <p> {{ $t('LAN.setting_tips') }} </p>
            </div>
            <van-form @submit="onSubmit">
                <van-field
                    v-if="payPasswordFlag"  
                    v-model="oldPassword"
                    type="password"
                    maxlength="6"
                    clearable
                    :label="$t('LAN.old_pwd')"
                    left-icon="lock"
                    :placeholder="$t('LAN.input_old_pwd')"
                />
                <van-field
                    v-model="newPassword"
                    type="password"
                    maxlength="6"
                    clearable
                    :label="$t('LAN.pwd')"
                    left-icon="lock"
                    :placeholder="$t('LAN.input_pwd')"
                    
                    :rules="[{ validator:validatorPwd, required: true, message: $t('LAN.input_pwd') }]" 
                />
                <van-field
                    v-model="confirmPassword"
                    type="password"
                    maxlength="6"
                    clearable
                    :label="$t('LAN.confirm_pay_pwd')"
                    left-icon="lock"
                    :placeholder="$t('LAN.input_confirm_pay_pwd')"
                   
                    :rules="[{ validator:validatorConfirmPwd, required: true, message: $t('LAN.different') }]" 
                />
                <div style="margin: 16px;">
                    <van-button round block color="#6e87ea" native-type="submit">{{$t('LAN.confirm')}}</van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import sHeader from '@/components/Header'
import { useStore } from 'vuex'
import { Toast } from 'vant'
import { requestPayPassword } from '@/service/pay'
import { useI18n } from 'vue-i18n'

export default {
    setup() {
        const store = useStore()
        const { t } = useI18n()

        const state = reactive({
            success:false,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            payPasswordFlag:false,
 
        })

        onMounted(() => {
            state.payPasswordFlag = store.getters.payPasswordFlag;
        });

        const validatorPwd = () => {
            if (state.newPassword.length != 6 || !(/^\d{6}$/.test(state.newPassword)) ) {
                return false;
            }
            return true;
        }

        const validatorConfirmPwd = () => {
            return state.confirmPassword == state.newPassword;
        }


        const onSubmit = async () => {
            if (state.newPassword == state.oldPassword) {
                Toast.fail(t('LAN.same_password'))
                return;
            }
            Toast.loading(t('LAN.sending'));
            await requestPayPassword({
                oldPassword:state.oldPassword,
                password:state.newPassword,
                passwordConfirmation:state.confirmPassword
            });
             //yes表示已设置支付密码
            store.commit('setPayPasswordFlag', true);
            state.success = true;
            Toast(t('LAN.success'));
        }


        return {
            ...toRefs(state),
            validatorPwd,
            validatorConfirmPwd,
            onSubmit,

        }
    },
    components: {
        sHeader,
    }
}
</script>

<style lang="less" scoped>
/deep/ .custom-image .van-empty__image {
    width: 100px;
    height: 100px;
}

.payPassword {
    height: 100vh;
    .content {
        margin-top: 10px;
    }
}
.tips {
    margin: 20px;
    text-align: left;
    color: #8c8c8c;
    font-size: 14px;
}

</style>